@import "stylesheets/globals/colors";
@import "stylesheets/mixins/responsive";

.rk-default-nav {
  $logo-height: 40px;
  $logo-width: 130px;
  $logo-height-md: 35px;
  $logo-width-md: 100px;
  $logo-height-sm: 24px;
  $logo-width-sm: 75px;

  padding: 15px 0 10px 0;

  @include sm-down {
    padding: 20px 15px;
  }

  @include md-down {
    padding: 15px;
  }

  > div {
    height: $logo-height;

    @include sm-down {
      height: $logo-height-sm;
    }

    @include md-only {
      height: $logo-height-md;
    }
  }

  @keyframes color {
    0% {
      color: $orange;
    }
    14% {
      color: $yellow;
    }
    28% {
      color: $aquamarine;
    }
    43% {
      color: $blue;
    }
    57% {
      color: $purple;
    }
    71% {
      color: $pink;
    }
    86% {
      color: $red;
    }
    100% {
      color: $orange;
    }
  }

  .rk-logo {
    max-width: 100%;
    width: $logo-width;
    height: $logo-height;
    animation: color 30s linear infinite;

    @include md-only {
      width: $logo-width-md;
      height: $logo-height-md;
    }

    @include sm-down {
      width: $logo-width-md;
      height: $logo-height-md;
    }
  }

  .rk-nav-link {
    a {
      font-family: SFProDisplay, Sans-Serif, serif;
      font-weight: 400;
      font-size: 20px;
      color: $black;
      padding: 4px 0;
      text-transform: uppercase;

      @include sm-down {
        font-size: 16px;
      }

      &:hover {
        color: $black;
        border-bottom: 2px $orange solid;

        @include sm-down {
          border-bottom: none;
        }
      }
    }

    .rk-btn {
      transform: translateY(-10px);
    }

    .ant-col {
      text-align: right;
      margin-left: 20px;
    }
  }

  .rk-nav--account-menu-dropdown {
    bottom: 0;
    position: relative;
    padding-top: 2px;

    @include sm-down {
      display: none;
    }
  }

  .rk-nav--account-burger-dropdown {
    @include md-up {
      display: none;
    }
  }
}

