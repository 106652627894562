@import "stylesheets/globals/colors";

.ant-select-selection-item {
  min-width: 100%;
}

::-moz-selection,
::selection {
  background-color: $orange;
}

.ant-input:hover,
.ant-input-number:hover,
.ant-pagination-simple-pager input:hover,
.ant-pagination-options-quick-jumper input:hover,
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: $orange;
}

.ant-input:focus,
.ant-input-focused,
.ant-input-number:focus,
.ant-input-number-focused,
.ant-pagination-simple-pager input:focus,
.ant-pagination-options-quick-jumper input:focus,
.ant-pagination-simple-pager .input-focused,
.ant-pagination-options-quick-jumper .input-focused,
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: $orange;
  -webkit-box-shadow: 0 0 0 2px rgba(255, 140, 104, 0.2);
  box-shadow: 0 0 0 2px rgba(255, 140, 104, 0.2);
}

.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active,
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon,
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
  color: $orange;
}

.ant-pagination-item-active,
.ant-pagination-item-active:focus,
.ant-pagination-item-active:hover,
.ant-pagination-item:focus,
.ant-pagination-item:hover,
.ant-pagination-next:focus,
.ant-pagination-next:hover,
.ant-pagination-prev:focus,
.ant-pagination-prev:hover {
  border-color: $orange;

  a {
    color: $orange;
  }

  button {
    border-color: $orange;
  }

  .ant-pagination-item-link {
    color: $orange;
    border-color: $orange;
  }
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after,
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $orange;
}

.ant-checkbox-checked::after,
.ant-checkbox-checked .ant-checkbox-inner,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: $orange;
}
