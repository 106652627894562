@import "stylesheets/globals/colors";
@import "default-layout.scss";

.rk-admin-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .rk-header {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    z-index: 100;
    background: $grey-light;
    border-bottom: black 1px solid;
  }

  .rk-content {
    margin: 0 $default-layout-margin;
    flex-grow: 1;
    display: flex;

    @include sm-down {
      margin: 0 5px;
    }

    .no-margin {
      margin: 0 0-$default-layout-margin;

      @include sm-down {
        margin: 0 0-$default-layout-margin-sm;
      }
    }

    .rk-content-section {
      display: flex;
      flex-direction: column;
      min-height: 100%;

      .rk-content-expand {
        flex-grow: 1;
        flex-shrink: 0;
        padding: 60px 40px 40px 40px;
      }

      .rk-footer {
        flex-grow: 0;
        flex-shrink: 0;
      }
    }
  }

  .rk-center {
    text-align: center;
  }

  .rk-admin-sidebar {
    border-right: 1px solid;
    min-height: 100%;
  }
}



