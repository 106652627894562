$default-layout-margin: 80px;
$default-layout-margin-sm: 15px;

.rk-default-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .rk-content {
    flex-grow: 1;

    .no-margin {
      margin: 0 0-$default-layout-margin;

      @include sm-down {
        margin: 0 0-$default-layout-margin-sm;
      }
    }
  }

  .rk-center {
    text-align: center;
  }
}



