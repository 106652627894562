.rk-default-footer {
  margin: 200px 0 66px 0;
  //max-width: 990px;

  @include lg-only {
    //max-width: 930px;
    margin: 100px auto;
  }

  @include md-only {
    //max-width: calc(100vw - 60px);
    margin: 100px 30px;
  }

  @include sm-down {
    //max-width: calc(100vw - 30px);
    margin: 100px 15px;
  }

  .rk-footer-content {
    border-top: 1px $black solid;


    .rk-footer-links {
      padding: 40px 0 40px 0;

      a {
        font-family: "SFProDisplay", sans-serif;
        font-weight: 300;
        font-size: 14px;
        line-height: 19px;
        color: $black;
        padding: 2px 0;
        margin-bottom: 15px;
        //text-decoration: underline;

        &:hover {
          color: $black;
          text-decoration: underline $orange;
        }
      }

      p {
        font-size: 14px;
        font-weight: bold;
        text-transform: capitalize;
      }

      &:last-child {
        border-top: none;
        padding: 0;

        p {
          font-size: 14px;
          color: $black;
          font-weight: normal;
          text-transform: none;
        }
      }
    }

    .rk-footer-link {
      margin-bottom: 10px;

      p {
        text-transform: uppercase;
        letter-spacing: 0.3125px;
      }
    }
  }
}

.homepage__section {
  .rk-default-footer {
    margin: 0
  }
}

